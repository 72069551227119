export const useConfig = () => ({
	google: {
		signIn: "939532026467-kubt2q4sl8kclgb2ensm5ds0o33d7fcm.apps.googleusercontent.com"
	},
	extensionVersion: '1.10',
	baseUrl: 'https://anyloader.com/api/v1',
	// paypal: {
    //     id: 'ppn',
	// 	clientId: "AT5-PbwlOuOyWuX1iQpZAL1N6nG3t3qUvZKOrY2BgVZq-UQsDVe_9ILIfThtK5LNj0Ibhi_Ky-x_kWjs",
	// 	planId: "P-9MS760763X450093KMX2WWGA"
	// },
	paypal: {
		id: 'gpz',
		clientId: "AQzevbB8g5dY2wzi0i-DcfzZaGU5bSIs7ysNJGc-aJAfUKZuB2OTmrfsAO1z7dW766-fDul88EyTN_dX",
		planId: "P-4GW04865W5591221MMVYHQRA"
	},

	// baseUrl: 'http://localhost:3600/v1',
	// paypal: {
	// 	id: 'sandbox',
	// 	clientId: "AZhdjlWJTatTXY2pBwCS92QbDOrWpJBg1wr6hUX27MSCvN_QMvBze2UWzrVjRyvUSXx-rpKsHfqkunuG",
	// 	planId: "P-7S583770A3486044TMV2EDQY",
	// }
})